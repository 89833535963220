import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'firebase/storage';
import HomePage from './Home/HomePage';
import DemoPage from './HRVCalculator/DemoPage';
import AboutPage from './AboutPage';
import TeamPage from './TeamPage';
import Header from './Header';
import ContactPage from './ContactPage';
import WearablePage from './WearablePage';
//import RegisterPage from './RegisterPage'
import { getStorage } from "firebase/storage"
import DatasetsPage from './DatasetsPage';
import BlogPage from './About/BlogPage';
import BlogPostPage from './About/BlogPostPage';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import Footer from './Footer/Footer';
import Dashboard from './UserPanel/Dashboard';
import HeartRateMonitor from './Live/HeartRateMonitor';
import DataPipelineVisualization from './About/DataFlow';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app);
const root = ReactDOM.createRoot(document.getElementById('root'));
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();

root.render(
  <BrowserRouter>
   <Header></Header>
    <Routes>
      <Route path="/" element= {<HomePage />} />
      <Route path="/hrv-calculation-demo" element={<DemoPage />} />
      <Route path="/demo" element={<DemoPage />} />
      <Route path="/about-hrv" element={<AboutPage/>} />
      <Route path="/contact" element={<ContactPage/>} />
      <Route path="/open-hrv-team" element={<TeamPage/>} />
      <Route path="/hrv-datasets" element={<DatasetsPage/>} />
      <Route path="/wearables-we-support" element={<WearablePage/>} />
      <Route path="/blog" element={<BlogPage/>} />
      <Route path="/blog/:postId" element={<BlogPostPage/>} />
      <Route path='/dashboard' element={<Dashboard/>} />
      <Route path='/live' element={<HeartRateMonitor/>} />
      <Route path='/dataflow' element={<DataPipelineVisualization/>} />
      <Route path='/dataset' element={<DatasetsPage/>} />
      <Route path='/datasets' element={<DatasetsPage/>} />
    </Routes>
    <Footer></Footer>
  </BrowserRouter>
);
logEvent(analytics, 'page rendered');
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Named export
export { analytics, storage };